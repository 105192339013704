import React from 'react';

// Path to the logo file on your project
import OperaLogo from '../assets/opera-logo.svg';

const Logo = () => (
  <img src={OperaLogo} alt="Utah Opera Logo" style={{ width: 180 }} />
);

export default Logo;
